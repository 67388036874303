import React from "react";

function Key() {
  return (
    <div className="flex pt-[20px] ml-[234px]  items-center flex-col gap-2 flex-1 overflow-auto z-10">
      <div className="flex flex-col  max-w-1200 w-full pb-[40px]">
        <div className="flex h-[40px] ">
          <div className="text-white text-[28px] h-[40px] leading-10 font-semibold font-['PingFang SC']">
            赚取KEY
          </div>
          <div className="mx-[10px] mt-[14px] text-white text-sm font-medium font-['PingFang SC']">
            可免费领取KEY数量：743
          </div>
          <div className=" cursor-pointer  mt-[13px] w-9 h-4 bg-violet-500 rounded-[31px] text-white text-[10px] font-normal font-['PingFang SC'] flex justify-center items-center" >
          领取
          </div>
        </div>
      <div className="mt-[10px] mb-[20px] w-[auto] mr-[20px] h-[1px] bg-white"></div>

      <div className="text-white text-[28px] font-semibold font-['PingFang SC']">任务</div>
      {/* <div className="w-[356px] h-[92px] bg-purple-900 rounded-[21px]" >

      </div> */}
      </div>

    </div>
  );
}

export default Key;
