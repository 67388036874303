import React from "react";
import TipPng from "/assets/tip.svg";
import UpPng from "/assets/up.svg";

const Item = () => (
  <div className="w-[356px] h-[94px]  relative ">
    <div className="w-[356px] h-[94px] left-0 top-0 absolute bg-purple-900 rounded-[21px]" />
    <div className="left-[20px] top-[34px] absolute text-white text-lg font-semibold font-['PingFang SC']">
      01
    </div>
    <img
      className="w-[71px] h-[72px] left-[54px] top-[11px] absolute rounded-full"
      src="https://via.placeholder.com/71x72"
    />
    <div className="w-[127px] h-6 left-[139px] top-[11px] absolute text-white text-[17px] font-semibold font-['PingFang SC']">
      WombatClassic
    </div>
    <div className="w-[85px] h-3.5 left-[139px] top-[44px] absolute text-stone-300 text-[10px] font-normal font-['PingFang SC']">
      @Wombat_Classic
    </div>
    <div className="left-[139px] top-[68px] absolute text-stone-300 text-[10px] font-normal font-['PingFang SC']">
      Peice：
    </div>
    <div className="w-11 h-3.5 left-[202px] top-[68px] absolute text-stone-300 text-[10px] font-normal font-['PingFang SC']">
      Volume：
    </div>
    <div className="w-[15px] h-3.5 left-[175px] top-[68px] absolute text-white text-[10px] font-normal font-['PingFang SC']">
      1.5
    </div>
    <div className="w-[15px] h-3.5 left-[247px] top-[68px] absolute text-white text-[10px] font-normal font-['PingFang SC']">
      1.5
    </div>
  </div>
);

function Main() {
  return (
    <div className="flex mt-[20px] ml-[234px]  items-center flex-col gap-2 flex-1 overflow-auto z-10">
      <div className="flex flex-col gap-2 max-w-1200 w-full pb-[40px]">
        <div className="flex justify-center items-center">
          <img className="w-[18px] mr-[3px]" alt="tip" src={TipPng} />
          <div className="text-white text-[13px] font-normal font-['PingFang SC'] mr-[5px]">
            2023 10/13 02:08 CZ开启了heyi的宝箱，获得0.01个ETH
          </div>
          <div className="text-white text-[13px] font-normal font-['PingFang SC']">
            2023 10/13 02:08 CZ开启了heyi的宝箱，获得0.01个ETH
          </div>
        </div>

        <div className="px-[20px]">
          <div className="flex mt-[20px] mb-[20px]  items-center relative">
            <div className="w-[114px] h-[38px] text-white text-[27px] font-semibold font-['PingFang SC']">
              Trending
            </div>

            <div className="absolute right-0 top-0 flex">
              <div className="w-[107px] h-7 bg-violet-500 rounded-[7px] flex justify-center items-center">
                <div className=" cursor-pointer  text-white text-[11px] font-normal font-['PingFang SC']">
                  24 HOURS
                </div>
                <img
                  className="w-[14px]  ml-[5px]"
                  alt="tip"
                  src={UpPng}
                />
              </div>
              <div className=" cursor-pointer  ml-[7px] flex justify-center items-center w-[91px] h-7 bg-white bg-opacity-5 rounded-[7px] border text-violet-500 text-[11px] border-violet-500 font-normal font-['PingFang SC']">
                VIEW ALL
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4">
            {Array(12)
              .fill(null)
              .map((_, i) => (
                <Item key={i} />
              ))}
          </div>
          <div className="flex mt-[20px] mb-[20px] items-center relative">
            <div className=" h-[38px] text-white text-[27px] font-semibold font-['PingFang SC']">
              Recent trade
            </div>

            <div className="absolute right-0 top-0 flex">
              <div className="w-[107px] h-7 bg-violet-500 rounded-[7px] flex justify-center items-center">
                <div className=" cursor-pointer  text-white text-[11px] font-normal font-['PingFang SC']">
                  0.01-0.1
                </div>
                <img
                  className="w-[14px]  ml-[5px]"
                  alt="tip"
                  src={UpPng}
                />
              </div>
              <div className=" cursor-pointer  ml-[7px] flex justify-center items-center w-[91px] h-7 bg-white bg-opacity-5 rounded-[7px] border text-violet-500 text-[11px] border-violet-500 font-normal font-['PingFang SC']">
                VIEW ALL
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4">
            {Array(12)
              .fill(null)
              .map((_, i) => (
                <Item key={i} />
              ))}
          </div>

          <div className="flex mt-[20px] mb-[20px] items-center relative">
            <div className=" h-[38px] text-white text-[27px] font-semibold font-['PingFang SC']">
              Recent join
            </div>

            <div className="absolute right-0 top-0 flex">
              <div className="w-[107px] h-7 bg-violet-500 rounded-[7px] flex justify-center items-center">
                <div className=" cursor-pointer  text-white text-[11px] font-normal font-['PingFang SC']">
                  ALL
                </div>
                <img
                  className="w-[14px]  ml-[5px]"
                  alt="tip"
                  src={UpPng}
                />
              </div>
              <div className=" cursor-pointer  ml-[7px] flex justify-center items-center w-[91px] h-7 bg-white bg-opacity-5 rounded-[7px] border text-violet-500 text-[11px] border-violet-500 font-normal font-['PingFang SC']">
                VIEW ALL
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4">
            {Array(12)
              .fill(null)
              .map((_, i) => (
                <Item key={i} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Main;
