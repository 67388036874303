import React from "react";
import Logo from "/assets/logo.png";
import SearchPng from "/assets/search.svg";
import { useMUD } from "../MUDContext";
import { shortenAddress } from "../utlis";


function Search() {
  const {
  network: {walletClient },
} = useMUD();


  return (
    <div className="flex items-center  fixed h-[58px] left-0 top-0 px-[20px] bg-[#301753] w-full">
      <div className="w-[190px] h-9 left-[20px] top-[11px]  ">
        <img className="w-[190px]" alt="logo" src={Logo} />
      </div>
      <div className="flex-auto h-9 ml-[30px] mr-[20px] bg-purple-900  rounded-[21px] border px-1">
        <div className="flex">
          <img
            className="w-[18px] mt-[8px] ml-[20px]"
            alt="logo"
            src={SearchPng}
          />
          <input className="w-[100%] bg-purple-900 text-white text-[13px] mt-[8px] ml-[5px] border-0 outline-0" />
        </div>
      </div>
      <div className="">
        <div className="w-[75px] h-7  bg-gray-700 rounded-[45px] flex justify-center  items-center cursor-pointer">
          <div className="w-9 h-7 flex justify-center items-center text-white text-[11px] font-normal font-['PingFang SC']">
            EN
          </div>
          <div className="w-9  h-7 flex justify-center items-center bg-violet-500 rounded-[45px] text-white text-[11px] font-normal font-['PingFang SC']">
            ZH
          </div>
        </div>
      </div>
      <div className="ml-[20px] cursor-pointer  px-[20px] h-7 flex justify-center items-center bg-violet-500 rounded-[45px] text-white text-[11px] font-normal font-['PingFang SC']">
        {shortenAddress(walletClient.account.address)}
      </div>
    </div>
  );
}
export default Search;
