import { mudConfig } from "@latticexyz/world/register";

export default mudConfig({
  tables: {
    Counter: {
      keySchema: {},
      valueSchema: "uint32",
    },
    User: {
      keySchema: {
        addr: "address",
      },
      valueSchema: {
        id:"uint256",
        boxTotal: "uint32",
        boxOpen: "uint32",
        boxUnOpen: "uint32",
        username: "bytes32",
        peice: "uint32",
        volume: "uint32",
        updateBlockTime: "uint32",
        createBlockTime: "uint32",
        fans: "address[]",
        follow:"address[]",
      },
    },
  },
});
