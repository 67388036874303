import React, { useEffect, useState } from "react";
import XPng from "/assets/x.svg";
import LikePng from "/assets/like.svg";
import UnlikePng from "/assets/unlike.svg";
import BoxOpenPng from "/assets/box-open.svg";
import { useMUD } from "../MUDContext";
import { shortenAddress } from "../utlis";
import { stringToHex, hexToString } from "viem";
import { useComponentValue, useEntityQuery } from "@latticexyz/react";

const Item = ({ onOpen }) => {
  return (
    <div className="grid  cursor-pointer place-content-center py-[15px] w-[142px] h-[212px] bg-purple-900 rounded-[21px]">
      <img className="w-[92px] h-[92px] mx-auto" alt="logo" src={BoxOpenPng} />
      <div className="text-center text-white text-xs font-semibold font-['PingFang SC']">
        KVC的BOX
      </div>
      <div className=" my-[5px] text-center text-white text-xs font-normal font-['PingFang SC']">
        LV1
      </div>
      <p className=" text-nowrap text-center text-white text-xs font-normal font-['PingFang SC']">
        刷新时间：21:32:06
      </p>
      <div
        onClick={onOpen}
        className="mx-auto mt-[17px] w-[54px] h-5 bg-gradient-to-r from-violet-500 to-blue-400 rounded-[45px] text-center text-white text-[11px] font-normal font-['PingFang SC'] flex justify-center items-center"
      >
        {/* 加速 */}
        开启
      </div>
    </div>
  );
};

const Modal = ({ onClose, openBoxFn, name }) => {
  return (
    <div
      onClick={onClose}
      className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center p-4"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className=" py-[20px] w-[377px] h-[159px] bg-purple-900 rounded-[21px] border border-violet-500"
      >
        <div className="flex justify-center items-center mx-auto  h-5 text-white text-sm font-semibold font-['PingFang SC']">
          确定开启来自{name}的LV1等级BOX吗？
        </div>
        <div className="flex justify-center items-center mt-[15px] text-white text-[13px] font-normal font-['PingFang SC']">
          总计：1个BOX
        </div>
        <div className="flex justify-center items-center mt-[5px] text-white text-[13px] font-normal font-['PingFang SC']">
          共计花费：1KEY
        </div>

        <div className="flex justify-center items-center mt-[20px]">
          <div
            onClick={openBoxFn}
            className="cursor-pointer  flex justify-center items-center w-12 h-[21px] bg-violet-500 rounded-[7px] text-white text-[10px] font-normal font-['PingFang SC']"
          >
            确定
          </div>

          <div
            onClick={onClose}
            className=" cursor-pointer ml-[15px] flex justify-center items-center w-12 h-[21px] border border-violet-500 rounded-[7px] text-white text-[10px] font-normal font-['PingFang SC']"
          >
            取消
          </div>
        </div>
      </div>
    </div>
  );
};

function Box() {
  const {
    components: { User },
    systemCalls: { join, setUsername, openBox },
    network: { walletClient, playerEntity },
  } = useMUD();

  const [open, setOpen] = useState(false);

  const _user = useComponentValue(User, playerEntity);
  console.log(_user, "_user");

  const initName =
    _user?.username &&
    _user?.username !==
      "0x0000000000000000000000000000000000000000000000000000000000000000"
      ? hexToString(_user?.username)
      : walletClient.account.address?.substr(-8);

  useEffect(() => {
    setInputValue(initName ?? "");
  }, [initName]);

  const [inputValue, setInputValue] = useState(initName);

  const [hasChange, setHasChange] = useState(false);

  const setUsernameFn = async () => {
    const newName = stringToHex(inputValue.trim(), { size: 32 });
    console.log(newName, "newName");
    await setUsername(newName);
    alert("修改成功");
  };

  const joinFn = async () => {
    await join();
    alert("加入成功");
  };

  const onChange = (event) => {
    if (!hasChange) {
      setHasChange(true);
    }
    // console.log(event.target.value, "inputValue",stringToHex(event.target.value, { size: 32 }));
    setInputValue(event.target.value);
  };

  const openBoxFn = async () => {
    await openBox(1);
    setOpen(false);
    alert("已开启");
  };

  return (
    <div className="flex pt-[20px] ml-[234px]  items-center flex-col gap-2 flex-1 overflow-auto z-10">
      <div className="flex flex-col gap-2 max-w-1200 w-full pb-[40px]">
        <div className="w-[auto] mr-[20px] h-[91px] bg-purple-900 rounded-[21px] px-[28px] py-[10px] shadow flex relative">
          <img
            className="w-[71px] h-[71px] rounded-full "
            src="https://via.placeholder.com/71x71"
          />
          <div className="ml-[15px] grid  content-between">
            <div className="flex">
              <input
                value={inputValue}
                className=" text-white text-[17px] bg-purple-900  font-semibold font-['PingFang SC']"
                onChange={onChange}
              />
              {hasChange && (
                <div onClick={setUsernameFn} className="text-white ml-[-40px]">
                  修改
                </div>
              )}
              {!_user && (
                <div onClick={joinFn} className="text-white ml-[-40px]">
                  加入
                </div>
              )}
              {/* WombatClassic
              </div> */}
              <div className="w-3.5 h-3.5 flex justify-center items-center bg-white bg-opacity-20 rounded-full mx-[7px] relative">
                <img className="w-2 h-2 " src={XPng} />
                <div className="whitespace-nowrap px-[8px] py-[6px] cursor-pointer bg-[#301753] rounded-[52px] absolute top-[-25px] left-1/2 -translate-x-1/2 text-white text-[9px] font-normal font-['PingFang SC']">
                  Go to Twitter Account
                </div>
              </div>

              <img className="w-3.5 h-3.5 " src={LikePng} />
            </div>

            <div className=" text-stone-300 text-[10px] font-normal font-['PingFang SC']">
              @Wombat_Classic
            </div>
            <div className="text-white text-[10px] font-normal font-['PingFang SC']">
              {shortenAddress(walletClient.account.address)}
            </div>
          </div>

          <div className=" absolute right-[28px] flex">
            <div className="grid">
              <div className="text-right text-stone-300 text-sm font-semibold font-['PingFang SC']">
                我的BOX
              </div>
              <div className="mt-[7px] text-right text-white text-[17px] font-semibold font-['PingFang SC']">
                {_user?.boxTotal}
              </div>
            </div>
            <div className="grid mx-[100px]">
              <div className="text-right text-stone-300 text-sm font-semibold font-['PingFang SC']">
                已开启
              </div>
              <div className="mt-[7px] text-right text-white text-[17px] font-semibold font-['PingFang SC']">
                {_user?.boxOpen}
              </div>
            </div>
            <div className="grid">
              <div className="text-right text-stone-300 text-sm font-semibold font-['PingFang SC']">
                未开启
              </div>
              <div className="mt-[7px] text-right text-white text-[17px] font-semibold font-['PingFang SC']">
                {_user?.boxUnOpen}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[20px] grid grid-cols-7 gap-[20px]">
          {Array(_user?.boxUnOpen)
            .fill(0)
            .map((item, i) => {
              return <Item onOpen={() => setOpen(true)} key={i} />;
            })}
        </div>

        {open && (
          <Modal
            name={initName}
            openBoxFn={openBoxFn}
            onClose={() => setOpen(false)}
          />
        )}
      </div>
    </div>
  );
}

export default Box;
