import { useComponentValue } from "@latticexyz/react";
import { useMUD } from "./MUDContext";
import { singletonEntity } from "@latticexyz/store-sync/recs";
import "./index.css";
import Home from "./pages/Home";

export const App = () => {
  const {
    components: { Counter },
    systemCalls: { increment },
    network: { walletClient },
  } = useMUD();

  const counter = useComponentValue(Counter, singletonEntity);

  // console.log(counter, "counter", walletClient);

  return (
    <>
      <Home />
    </>
  );
};
