import React from "react";

const Up = () => {
  return (
    <div className="flex flex-col  items-center px-[28px] py-[14px] w-[141px] h-[199px] bg-purple-900 rounded-[21px]">
      <img
        className="w-[85px] h-[85px] rounded-full"
        src="https://via.placeholder.com/85x85"
      />
      <p className="text-nowrap mt-[8px] mb-[5px] w-[39px] h-5 text-center text-white text-sm font-semibold font-['PingFang SC']">
        Galxe
      </p>
      <p className="whitespace-nowrap text-center mb-[5px]  text-stone-300 text-sm font-normal font-['PingFang SC']">
        153K+ Followers
      </p>
      <div  className=" cursor-pointer flex justify-center items-center w-[69px] h-7 rounded-[7px] border border-white text-center text-white text-[11px] font-normal font-['PingFang SC']">
        +Follow
      </div>
    </div>
  );
};

function Follow() {
  return (
    <div className="flex pt-[20px] ml-[234px]  items-center flex-col gap-2 flex-1 overflow-auto z-10">
      <div className="flex flex-col gap-[20px]   w-full pb-[40px]">
        <div className="grid grid-cols-7 max-w-1200 gap-[20px]">
        {Array(10).fill(0).map((item,i)=>{
          return (
            <Up key={i} />
          )
        })}
        </div>

       
      </div>
    </div>
  );
}

export default Follow;
