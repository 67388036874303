import React from "react";

function List() {
  return (
    <div className="flex pt-[20px] ml-[234px]  items-center flex-col gap-2 flex-1 overflow-auto z-10">
      <div className="flex flex-col  max-w-1200 w-full pb-[40px]">
        <div className="w-[auto] p-[20px] mr-[20px] h-[57px] bg-violet-950 rounded-tl-[21.34px] rounded-tr-[21.34px]">
          <div className="opacity-60 text-white text-xs font-medium font-['PingFang SC']">
            姓名
          </div>
        </div>
        <div>
          <div className="w-[auto] bg-[#462c73] mr-[20px] mt-0 h-[70px] flex px-[20px] py-[14px] ">
            <img
              className="w-[43px] h-[43px]  rounded-full"
              src="https://via.placeholder.com/43x43"
            />
            <div className="grid ml-[7px] content-between">
              <div className="text-white text-[17px] font-semibold font-['PingFang SC']">
                WombatClassic
              </div>
              <div className="text-stone-300 text-[10px] font-normal font-['PingFang SC']">
                @Wombat_Classic
              </div>
            </div>

            <div className="flex justify-center items-center mx-auto text-white text-[17px] font-semibold font-['PingFang SC']">
              20个宝箱待开启
            </div>
            <div className="flex items-center">

            <div className="flex justify-center items-center content-center w-[114px] h-7 bg-gradient-to-r from-violet-500 to-blue-400 rounded-[45px] text-right text-white text-sm font-medium font-['PingFang SC']">
              去帮好友开启
            </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default List;
