import React, { useState } from "react";
import Search from "../components/Search";
import Main from "../components/Main";
import ActivePng from "/assets/active.svg";
import HomePng from "/assets/home.svg";
import BoxPng from "/assets/box.svg";
import AirPng from "/assets/air.svg";
import KeyPng from "/assets/key.svg";
import FriendPng from "/assets/friend.svg";
import AssetsPng from "/assets/assets.svg";
import AddressListPng from "/assets/address-list.svg";
import Box from "./Box";
import List from "./List";
import Follow from "./Follow";
import Key from "./Key";


function Home() {
  const [active, setActive] = useState(0);

  const list = [
    {
      text: "主页",
      icon: HomePng,
      render:<Main />
    },
    {
      text: "我的BOX",
      icon: BoxPng,
      render:<Box />
    },
    {
      text: "领取空投",
      icon: AirPng,
    },
    {
      text: "赚取KEY",
      icon: KeyPng,
      render:<Key />
    },
    {
      text: "我的资产",
      icon: AssetsPng,
    },
    {
      text: "好友列表",
      icon: AddressListPng,
      render:<List />
    },
    {
      text: "关注好友",
      icon: FriendPng,
      render:<Follow/>
    },
  ];

  return (
    <div className="flex items-center justify-between  bg-[#1D063D] relative  pt-[58px] leading-none">
      <Search />
      <div className="flex flex-row gap-2  h-[calc(100vh-58px)] w-full">
        <div className="flex flex-row gap-2 mt-[20px] w-full ml-[20px]   fixed">
          <div className="w-[196px] h-[666px] bg-violet-950 bg-opacity-30 rounded-[21px] border border-violet-500 px-[20px] py-[35px]">
            {list.map((item, i) => (
              <div key={i}  onClick={() => setActive(i)} className=" relative mb-[24px] w-[156px] h-[37px] cursor-pointer">
                {active==i&&<img src={ActivePng} className=" absolute left-0 top-0 z-0" />}
                <img src={item.icon} className=" absolute left-[20px] top-[8px]" />
                <div
                 
                  className="z-1  absolute top-[7px] left-[45px] text-white text-base font-normal font-['PingFang SC']"
                >
                  {item.text}
                </div>
              </div>
            ))}
          </div>
        </div>
        {list[active].render}
      </div>
    </div>
  );
}

export default Home;
